import React from "react";

const Footer = () => {

  return (
    <div className="footer">
      <div className="name">
        © 2021 Leana Neparidze
      </div>
    </div>
  )
}

export default Footer;